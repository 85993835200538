<div class="homepage-content">
  <div class="main-container pb-2">
    <breadcrumb></breadcrumb>
  </div>
  <div class="d-flex justify-content-between align-items-center pb-16">
    <div class="d-flex align-items-center gap-3">
      <h1 class="fs-2 fw-bold no-margin">{{ "proposal.TITLE" | translate }}</h1>
      <div class="badge" *ngIf="phaseInfo?.endDate">
        <span>
          {{ (('proposal.SEND_BEFORE' | translate) || '') +((phaseInfo?.endDate | date : 'dd/MM/yyyy') || '')}}</span>
      </div>
    </div>
  </div>

  <loader *ngIf="isLoadingPhaseInfo" [marginBlock]="'150px'"></loader>

  <!-- Contenuto pagina -->
  <div class="proposal-content" *ngIf="!isLoadingPhaseInfo && tabList.length">
    <div class="d-flex flex-row w-100 justify-content-between align-items-center gap-12 header-action-row">
      <!-- Tab selezione vista team-->
      <nga-tab-menu role="tablist" tabMenuType="button" [items]="tabList" [activeItem]="activeTab"
        (onTabClicked)="changeTeamTab($event)">
      </nga-tab-menu>

      <!-- Filtro gerarchie -->
      <nga-button [id]="'hierarchy'" variant="secondary" type="text" *ngIf="(activeTab.id??'') == 'EXTENDED'"
        [label]="getHierarchyActionLabel() | uppercase" (click)="openHierarchySidebar()" type="text"
        leftIconSrc="assets/icons/hierarchy.svg">
      </nga-button>
    </div>
    <!-- Dashboard -->
    <proposal-dashboard *ngIf="!chargingDataForHierarchy" [summary]="dashboardSummary"
      (onExpand)="getDashboardExtendedData()" [processType]="processType" [budgetExtendedData]="budgetExtendedData"
      [genderExtendedData]="genderExtendedData"></proposal-dashboard>

    <loader *ngIf="chargingDataForHierarchy" class="w-100" [marginBlock]="'50px'"></loader>

    <!-- Azioni pagina, download report, recap filtri -->
    <div class="d-flex flex-column justify-content-between w-100 gap-12">
      <!-- Label filtri attivi -->
      <div class="d-flex flex-row flex-fill justify-content-start" *ngIf="filters.getChips().length">
        <p>{{ "proposal.filter.ACTIVE_FILTERS" | translate}}</p>
      </div>
      <!-- Riga con recap filtri attivi (chips) e azioni pagina-->
      <div class="d-flex flex-row justify-content-start w-100 chips-filters-actions-container">
        <!-- Chips filtri-->
        <div class="d-flex flex-row flex-wrap gap-12 flex-fill chips-container-wrapper">
          <ng-container *ngFor="let chip of filters.getChips()">
            <!-- Rimuovo il filtro e richiamo tableData -->
            <button class="recap-chips" (click)="filters.removeFilter(chip.id, chip.key); loadTeamData(true)">
              <p>{{ chip.title }}</p>
              <nga-icon src="assets/icons/close.svg"></nga-icon>
            </button>
          </ng-container>
        </div>

        <!-- azioni pagina -->
        <div class="filter-action-container">
          <!-- Scarica report -->
          <nga-button [id]="'report-download'" variant="secondary" type="text"
            *ngIf="phaseInfo?.canDownloadProposalsReport && (activeTab?.id ?? '') == 'EXTENDED'"
            [label]="('proposal.actions.DOWNLOAD' | translate)??''" [disabled]="isDownloadingReport"
            (click)="downloadProposalReport()" type="text" leftIconSrc="assets/icons/download.svg"></nga-button>
          <!-- Filtri -->
          <nga-button [id]="'filter'" variant="secondary" type="text"
            [label]="('proposal.actions.FILTERS' | translate | uppercase)??''" (click)="openFiltersSidebar()"
            type="text" leftIconSrc="assets/icons/filter-menu.svg">
          </nga-button>
          <!-- Invia proposte -->
          <nga-button *ngIf="(activeTab.id ?? '') != 'EXTENDED'" [id]="'send-proposal'" variant="secondary"
            [disabled]="!phaseInfo?.canConfirm"
            [label]="('proposal.actions.SEND_PROPOSALS' | translate | uppercase)??''"
            (click)="openConfirmSendProposalModal()">
          </nga-button>
        </div>
      </div>
    </div>

    <!-- Tabella dati utenti -->
    <proposal-table [isLoadingData]="isLoadingTableData" [count]="userCount" [list]="userList ?? []"
      [teamType]="activeTab.id ?? ''" [headerList]="tableHeaderList" [page]="currentPage" [pageSize]="numRecords"
      [sortData]="sortData" (changeOrder)="changeTableOrder($event)" (onPageChanged)="pageChanged($event)"
      [phaseInfo]="phaseInfo" (onPropose)="openProposalSidebar($event)"></proposal-table>
  </div>
  <div class="d-flex flex-row justify-content-start align-content-center placeholder-no-data"
    *ngIf="!isLoadingPhaseInfo && !tabList.length">
    <h4>{{ 'proposal.NO_TEAM_DATA' | translate }}</h4>
  </div>
</div>

<ng-template #contentProposalSidebar let-offcanvas>
  <div class="offcanvas-header d-flex justify-content-between">
    <p class="content-sidebar-title" id="content-sidebar-title-text">
      {{selectedUser ? (selectedUser.forename + " " + selectedUser.surname) : ""}}
    </p>
    <div class="d-flex flex-row align-items-center">
      <button type="button" class="btn icon-button" (click)="closeProposalSidebar()"
        aria-label="Chiudi sidebar proposte">
        <img alt="" src="assets/icons/close.svg" />
      </button>
    </div>
  </div>

  <nga-tab-menu role="tablist" [activeItem]="activeTabProposal" [items]="proposalTabs" [fullWidthTabs]="true"
    [disabled]="isLoadingAnagUserDetails || isLoadingProposal" (onTabClicked)="changeProposalTab($any($event))">
  </nga-tab-menu>

  <div id="propose-sidenav" class="offcanvas-body">
    <div *ngIf="!isLoadingProposal && activeTabProposalId == 'current-proposal'"
      class="proposal-container d-flex flex-column">
      <div *ngIf="userProposalDetails?.currentProposal?.statusType"
        class="d-flex flex-row w-100 proposal-status-container align-items-center">
        <div class="badge-{{userProposalDetails?.currentProposal?.statusType?.toLowerCase()}}"></div>
        <div class="d-flex flex-column">
          <p>{{ userProposalDetails?.currentProposal?.lastUpdateDate | date : 'dd/MM/yyyy' }}</p>
          <p class="bold">{{('proposal.status.' + userProposalDetails?.currentProposal?.statusType) | translate}} - {{
            userProposalDetails?.currentProposal?.proposerUserSurename }}</p>
        </div>
      </div>
      <!-- Tipologia di intervento -->
      <p class="margin-bottom4">{{ "proposal.INTERVENTION_TYPE" | translate }}</p>
      <custom-select bindLabel="id" containerClass="cursor-pointer full-dimension custom-select gray-background"
        [bindModel]="selectedProposalOptionType || defaultOptionSelected" [items]="proposalAvailableOptions"
        [labelForId]="'input-select-proposal-type'" [attrAriaLabel]="'generic.SELECT' | translate" bindLabel="label"
        [required]="true" [parameterNameOfSelectedItem]="'label'" placeholderLabel="" bindValue="id"
        (onSelectionChanged)="onSelectedProposalOptionChanged($event)" [disabled]="isLoadingProposal">
      </custom-select>
      <!-- Promozione a dirigente / Promozione a funzionario -->
      <div
        *ngIf="selectedProposalOptionType?.id != 'EXECUTIVE_PROMOTION' && selectedProposalOptionType?.id != 'OFFICER_PROMOTION'">
        <div *ngFor="let container of proposalFormInfo; let last = last" class="proposal-form">
          <div class="position-relative" *ngFor="let field of container?.fields">
            <p class="field-text margin-top20 margin-bottom4" *ngIf="field.type == 'STI_TYPE'">
              {{'proposal.TIPOLOGY_STI_VARIATION' | translate}}
            </p>
            <custom-select *ngIf="field.type == 'STI_TYPE'" bindLabel="id"
              containerClass="cursor-pointer full-dimension custom-select gray-background"
              [bindModel]="selectedOptionStiType.get(container.economicMeasureType)" [items]="stiTypeOptions"
              [labelForId]="'input-select-sti-type'" [attrAriaLabel]="'generic.SELECT' | translate" bindLabel="label"
              [required]="false" [parameterNameOfSelectedItem]="'label'"
              placeholderLabel="{{'generic.SELECT' | translate}}" bindValue="id"
              (onSelectionChanged)="onSelectedStiTypeOptionChanged($event, container.economicMeasureType, true)"
              [disabled]="false">
            </custom-select>
            <p class="field-text margin-top20 margin-bottom4" *ngIf="field.type == 'PERCENTAGE' &&
            selectedOptionStiType.get(container.economicMeasureType) &&
            selectedOptionStiType.get(container.economicMeasureType)?.id &&
            (selectedOptionStiType.get(container.economicMeasureType)?.id == 'BASELINE_PERCENTAGE' ||
            selectedOptionStiType.get(container.economicMeasureType)?.id == 'MAX_THEORETICAL_PERCENTAGE')">
              {{'proposal.INSERT_STI_TYPE_PERCENTAGE' | translate}}</p>
            <input *ngIf="field.type == 'PERCENTAGE' &&
              selectedOptionStiType.get(container.economicMeasureType) &&
              selectedOptionStiType.get(container.economicMeasureType)?.id &&
              (selectedOptionStiType.get(container.economicMeasureType)?.id == 'BASELINE_PERCENTAGE' ||
              selectedOptionStiType.get(container.economicMeasureType)?.id == 'MAX_THEORETICAL_PERCENTAGE')"
              id="inputProposalForm-{{ field.type }}-{{container.economicMeasureType}}" [disabled]="false" type="text"
              (blur)="onBlurInputAmount($event, field.type, container.economicMeasureType)"
              (keyup.enter)="onBlurInputAmount($event, field.type, container.economicMeasureType)"
              [(ngModel)]="proposalFieldMap.get(container.economicMeasureType)![field.type]"
              placeholder="{{'proposal.INSERT_PERCENTAGE' | translate}}" class="proposal-form-input"
              [required]="field.required ?? false"
              (ngModelChange)="onInputProposalFormChange(field.type, container.economicMeasureType, $event)" />
            <div *ngIf="validateFormMap.get(container.economicMeasureType)!['fieldType']
            && field.type == 'PERCENTAGE'
            && selectedOptionStiType.get(container.economicMeasureType) &&
            selectedOptionStiType.get(container.economicMeasureType)?.id &&
            (selectedOptionStiType.get(container.economicMeasureType)?.id == 'BASELINE_PERCENTAGE' ||
            selectedOptionStiType.get(container.economicMeasureType)?.id == 'MAX_THEORETICAL_PERCENTAGE')
            && (firstTimeOpenedSidebarWithExistentPropose ? true : passedOnBlurInput)"
              class="input-icons-container d-flex align-items-center justify-content-center">
              <img class="cursor-pointer"
                *ngIf="isFieldValueValid(proposalFieldMap.get(container.economicMeasureType)![field.type])"
                (click)="clearSearch(container.economicMeasureType, field.type)" src="./assets/icons/close-outline.svg"
                alt="" />
              <img *ngIf="validateFormMap.get(container.economicMeasureType)!['isValid']"
                src="./assets/icons/checkmark-circle.svg" alt="" />
              <img *ngIf="!(validateFormMap.get(container.economicMeasureType)!['isValid'])"
                src="./assets/icons/alert-circle.svg" alt="" />
            </div>
            <p class="field-text margin-top20 margin-bottom4" *ngIf="container.economicMeasureType == 'STI_UPDATE' && field.type == 'AMOUNT' &&
            selectedOptionStiType.get(container.economicMeasureType) &&
            selectedOptionStiType.get(container.economicMeasureType)?.id &&
            (selectedOptionStiType.get(container.economicMeasureType)?.id == 'BASELINE_AMOUNT' ||
            selectedOptionStiType.get(container.economicMeasureType)?.id == 'MAX_THEORETICAL_AMOUNT')">
              {{'proposal.INSERT_IMPORT_STI_VARIATION' | translate}}</p>
            <input *ngIf="container.economicMeasureType == 'STI_UPDATE' && field.type == 'AMOUNT' &&
                          selectedOptionStiType.get(container.economicMeasureType) &&
                          selectedOptionStiType.get(container.economicMeasureType)?.id &&
                          (selectedOptionStiType.get(container.economicMeasureType)?.id == 'BASELINE_AMOUNT' ||
                          selectedOptionStiType.get(container.economicMeasureType)?.id == 'MAX_THEORETICAL_AMOUNT')"
              id="inputProposalForm-{{ field.type }}-{{container.economicMeasureType}}" [disabled]="false" [ngClass]="{'error-border-input':
              ((validateFormMap.get(container.economicMeasureType)!['fieldType'] == 'AMOUNT') &&
              !(validateFormMap.get(container.economicMeasureType)!['isValid']))
              && field.type == 'AMOUNT'}" type="text"
              (blur)="onBlurInputAmount($event, field.type , container.economicMeasureType)"
              (keyup.enter)="onBlurInputAmount($event, field.type, container.economicMeasureType)"
              [(ngModel)]="proposalFieldMap.get(container.economicMeasureType)![field.type]"
              placeholder="{{'proposal.INSERT_EURO' | translate}}" class="proposal-form-input"
              (keypress)="onKeyPressInput($event)" [required]="field.required ?? false"
              (ngModelChange)="onInputProposalFormChange(field.type, container.economicMeasureType, $event)" />

            <div
              *ngIf="(validateFormMap.get(container.economicMeasureType)!['fieldType'] == 'AMOUNT')
              && field.type == 'AMOUNT' &&
              selectedOptionStiType.get(container.economicMeasureType) &&
              selectedOptionStiType.get(container.economicMeasureType)?.id &&
              (selectedOptionStiType.get(container.economicMeasureType)?.id == 'BASELINE_AMOUNT' ||
              selectedOptionStiType.get(container.economicMeasureType)?.id == 'MAX_THEORETICAL_AMOUNT') && container.economicMeasureType == 'STI_UPDATE' && (firstTimeOpenedSidebarWithExistentPropose ? true : passedOnBlurInput)"
              class="input-icons-container d-flex align-items-center justify-content-center">
              <img class="cursor-pointer"
                *ngIf="isFieldValueValid(proposalFieldMap.get(container.economicMeasureType)![field.type])"
                (click)="clearSearch(container.economicMeasureType, field.type)" src="./assets/icons/close-outline.svg"
                alt="" />
              <img *ngIf="validateFormMap.get(container.economicMeasureType)!['isValid']"
                src="./assets/icons/checkmark-circle.svg" alt="" />
              <img *ngIf="!(validateFormMap.get(container.economicMeasureType)!['isValid'])"
                src="./assets/icons/alert-circle.svg" alt="" />
            </div>
            <p class="field-text margin-top20 margin-bottom4"
              *ngIf="field.type == 'AMOUNT' && container.economicMeasureType != 'STI_UPDATE'">
              {{getImportLabel(container.economicMeasureType)}}</p>
            <input *ngIf="field.type == 'AMOUNT' && container.economicMeasureType != 'STI_UPDATE'"
              id="inputProposalForm-{{ field.type }}-{{container.economicMeasureType}}" [disabled]="false" [ngClass]="{'error-border-input':
              ((validateFormMap.get(container.economicMeasureType)!['fieldType'] == 'AMOUNT') &&
              !(validateFormMap.get(container.economicMeasureType)!['isValid']))
              && field.type == 'AMOUNT'}" type="text"
              (blur)="onBlurInputAmount($event, field.type, container.economicMeasureType)"
              (keyup.enter)="onBlurInputAmount($event, field.type, container.economicMeasureType)"
              [(ngModel)]="proposalFieldMap.get(container.economicMeasureType)![field.type]"
              placeholder="{{'proposal.INSERT_EURO' | translate}}" class="proposal-form-input"
              (keypress)="onKeyPressInput($event)" [required]="field.required ?? false"
              (ngModelChange)="onInputProposalFormChange(field.type, container.economicMeasureType, $event)" />
            <div
              *ngIf="(validateFormMap.get(container.economicMeasureType)!['fieldType'] == 'AMOUNT')
              && field.type == 'AMOUNT' && container.economicMeasureType != 'STI_UPDATE' && (firstTimeOpenedSidebarWithExistentPropose ? true : passedOnBlurInput)"
              class="input-icons-container d-flex align-items-center justify-content-center">
              <img class="cursor-pointer"
                *ngIf="isFieldValueValid(proposalFieldMap.get(container.economicMeasureType)![field.type])"
                (click)="clearSearch(container.economicMeasureType, field.type)" src="./assets/icons/close-outline.svg"
                alt="" />
              <img *ngIf="validateFormMap.get(container.economicMeasureType)!['isValid']"
                src="./assets/icons/checkmark-circle.svg" alt="" />
              <img *ngIf="!(validateFormMap.get(container.economicMeasureType)!['isValid'])"
                src="./assets/icons/alert-circle.svg" alt="" />
            </div>
            <p [ngClass]="{'error-text': (validateFormMap.get(container.economicMeasureType)!['fieldType'] == 'AMOUNT'
              && validateFormMap.get(container.economicMeasureType)!['showMaxGuidelinesAmountWarning'])
              && field.type == 'AMOUNT'}" class="detail-proposal-form-info-text margin-top4" *ngIf="field.type == 'AMOUNT' && container.economicMeasureType != 'STI_UPDATE'
              && additionalInfoProposalForm.get(container.economicMeasureType)!['maxGuidelinesAmount'] != null">
              {{"proposal.detailInfo.EROGABLE_MAX" |
              translate}}
              {{ getNumber(additionalInfoProposalForm.get(container.economicMeasureType)!['maxGuidelinesAmount']) |
              currency: 'EUR' : true:'1.2-2'}}
            </p>
            <p [ngClass]="{'error-text': (validateFormMap.get(container.economicMeasureType)!['fieldType'] == 'AMOUNT'
            && validateFormMap.get(container.economicMeasureType)!['showAvailableBudgetWarning'])
            && field.type == 'AMOUNT'}" class="detail-proposal-form-info-text margin-top2" *ngIf="field.type == 'AMOUNT' && container.economicMeasureType != 'STI_UPDATE'
              && getDisponibleBudget(container.economicMeasureType, 'availableBudget', true)">
              {{"proposal.detailInfo.DISPONIBLE_BUDGET" | translate}}
              {{getDisponibleBudgetNumber(container.economicMeasureType, 'availableBudget') | currency: 'EUR' :
              true:'1.2-2'}}
            </p>
            <div *ngIf="(isFieldValueValid(additionalInfoProposalForm.get(container.economicMeasureType)!['warningTypes'])
              && field.type == 'AMOUNT')
              || (validateFormMap.get(container.economicMeasureType)!['fieldType'] == 'AMOUNT' && field.type == 'AMOUNT'
              && isFieldValueValid(warningTypesMap.get(container.economicMeasureType) || []))">
              <div *ngFor="let warning of getWarningToCycle(container.economicMeasureType)"
                class="warning-container d-flex flex-row align-items-start">
                <img src="assets/icons/icon_warning.svg">
                <div class="d-flex flex-column">
                  <p class="bold">{{'generic.ATTENTION' | translate}}</p>
                  <p class="italic-text">{{('proposal.warnings.' + warning) | translate}}</p>
                </div>
              </div>
            </div>

            <p class="field-text margin-top20 margin-bottom4" *ngIf="field.type == 'CONTRACT_LEVEL'">
              {{'proposal.NEW_LEVEL' | translate}}</p>

            <input *ngIf="field.type == 'CONTRACT_LEVEL'" id="inputProposalForm-{{ field.type }}" [disabled]="true"
              type="text"
              [(ngModel)]="additionalInfoProposalForm.get(container.economicMeasureType)!['nextProcessContractLevelName']"
              [required]="field.required ?? false" placeholder="" class="proposal-form-input" />

            <p class="field-text margin-top20 margin-bottom4" *ngIf="field.type == 'LEVEL_ADVANCEMENT_COST'">
              {{'proposal.PRICE_LEVEL_ADVANCEMENT' | translate}}</p>

            <p class="field-text-value margin-left16" *ngIf="field.type == 'LEVEL_ADVANCEMENT_COST'">
              {{getLevelAdvancementCost(container.economicMeasureType)}}</p>

            <p [ngClass]="{'error-text': (validateFormMap.get(container.economicMeasureType)!['fieldType'] == 'ABSORBED_AMOUNT'
            && validateFormMap.get(container.economicMeasureType)!['showAvailableBudgetWarning'])
            && field.type == 'LEVEL_ADVANCEMENT_COST' }" class="detail-proposal-form-info-text margin-top12"
              *ngIf="field.type == 'LEVEL_ADVANCEMENT_COST' && getDisponibleBudget(container.economicMeasureType, 'availableBudget', true)">
              {{"proposal.detailInfo.RESIDUE_BUDGET" |
              translate}}
              {{getDisponibleBudgetNumber(container.economicMeasureType, 'availableBudget') | currency: 'EUR' :
              true:'1.2-2'}}
            </p>

            <div class="d-flex justify-content-between w-100 margin-top20 margin-bottom4"
              *ngIf="field.type == 'ABSORBED_AMOUNT'">
              <p class="field-text">{{ 'proposal.EVENTUAL_ABSORMENT_ADP' | translate }}</p>
              <div class="facultative-badge">
                <p>{{'proposal.OPTIONAL' | translate}}</p>
              </div>
            </div>

            <input *ngIf="field.type == 'ABSORBED_AMOUNT'"
              id="inputProposalForm-{{ field.type }}-{{container.economicMeasureType}}" [disabled]="false" [ngClass]="{'error-border-input': ((validateFormMap.get(container.economicMeasureType)!['fieldType'] == 'ABSORBED_AMOUNT') &&
              !(validateFormMap.get(container.economicMeasureType)!['isValid']))
              && field.type == 'ABSORBED_AMOUNT'}" type="text"
              (blur)="onBlurInputAmount($event, field.type, container.economicMeasureType, true)"
              (keyup.enter)="onBlurInputAmount($event, field.type, container.economicMeasureType, true)"
              [(ngModel)]="proposalFieldMap.get(container.economicMeasureType)![field.type]"
              placeholder="{{'proposal.INSERT_EURO' | translate}}" class="proposal-form-input"
              (keypress)="onKeyPressInput($event)" [required]="field.required ?? false"
              (ngModelChange)="onInputProposalFormChange(field.type, container.economicMeasureType, $event)" />

            <div
              *ngIf="(validateFormMap.get(container.economicMeasureType)!['fieldType'] == 'ABSORBED_AMOUNT')
              && field.type == 'ABSORBED_AMOUNT' && (firstTimeOpenedSidebarWithExistentPropose ? true : passedOnBlurInput)"
              class="input-icons-container d-flex align-items-center justify-content-center">
              <img class="cursor-pointer"
                *ngIf="isFieldValueValid(proposalFieldMap.get(container.economicMeasureType)![field.type])"
                (click)="clearSearch(container.economicMeasureType, field.type)" src="./assets/icons/close-outline.svg"
                alt="" />
              <img *ngIf="validateFormMap.get(container.economicMeasureType)!['isValid']"
                src="./assets/icons/checkmark-circle.svg" alt="" />
              <img *ngIf="!(validateFormMap.get(container.economicMeasureType)!['isValid'])"
                src="./assets/icons/alert-circle.svg" alt="" />
            </div>

            <p [ngClass]="{'error-text':
            (validateFormMap.get(container.economicMeasureType)!['fieldType'] == 'ABSORBED_AMOUNT'
            && !(validateFormMap.get(container.economicMeasureType)!['isValid']))
            && field.type == 'ABSORBED_AMOUNT'}" class="detail-proposal-form-info-text margin-top2"
              *ngIf="field.type == 'ABSORBED_AMOUNT' && getDisponibleBudget(container.economicMeasureType, 'absorbableAmount', true)">
              {{"proposal.detailInfo.MAX_ABSORBIBLE" | translate}}
              {{getDisponibleBudgetNumber(container.economicMeasureType, 'absorbableAmount') | currency: 'EUR' :
              true:'1.2-2'}}
            </p>
            <p *ngIf="(firstTimeOpenedSidebarWithExistentPropose ? true : passedOnBlurInput) && field.type == 'ABSORBED_AMOUNT' && ((validateFormMap.get(container.economicMeasureType)!['fieldType'] == 'ABSORBED_AMOUNT')
            && (validateFormMap.get(container.economicMeasureType)!['isValid']))"
              class="detail-proposal-form-info-text margin-top2">
              {{"proposal.detailInfo.MAX_ABSORBIBLE_DESC" |
              translate}}
            </p>
            <div
              *ngIf="(validateFormMap.get(container.economicMeasureType)!['fieldType'] == 'ABSORBED_AMOUNT' && field.type == 'ABSORBED_AMOUNT' && isFieldValueValid(errorTypesMap.get(container.economicMeasureType) || []))">
              <div *ngFor="let error of errorTypesMap.get(container.economicMeasureType)"
                class="warning-container error d-flex flex-row align-items-start">
                <img src="assets/icons/icon_warning_error.svg">
                <div class="d-flex flex-column">
                  <p class="bold">{{'generic.ATTENTION' | translate}}</p>
                  <p class="italic-text">{{('proposal.errors.' + error) | translate}}</p>
                </div>
              </div>
            </div>

            <p [ngClass]="{'disabled-text': !(isFieldValueValid(previewFormMap.get(container.economicMeasureType)!['baseSalaryPostReview'])) &&
          !isFieldValueValid(errorTypesMap.get(container.economicMeasureType) || [])}"
              class="field-text margin-top20 margin-bottom4" *ngIf="field.type == 'BASE_SALARY_POST_REVIEW'">
              {{(selectedProposalOptionType?.id == 'LEVEL_ADVANCEMENT_AND_ANNUAL_RETRIBUTION_INCREASE' &&
              container.economicMeasureType == 'ANNUAL_RETRIBUTION_INCREASE')
              ? ('proposal.BASE_SALARY_POST_PL_ADVANCEMENT' |
              translate) : container.economicMeasureType == 'LEVEL_ADVANCEMENT' ? ('proposal.BASE_SALARY_POST_REVIEW_PL'
              |
              translate) : ('proposal.BASE_SALARY_POST_REVIEW' | translate)}}</p>
            <input *ngIf="field.type == 'BASE_SALARY_POST_REVIEW' &&
                !(isFieldValueValid(previewFormMap.get(container.economicMeasureType)!['baseSalaryPostReview'])) &&
                !isFieldValueValid(errorTypesMap.get(container.economicMeasureType) || [])"
              id="inputProposalForm-{{ field.type }}-{{container.economicMeasureType}}" [disabled]="true" type="text"
              placeholder="{{'proposal.INSERT_IMPORT' | translate}}" class="proposal-form-input disabled-input" />
            <p class="field-text-value margin-top20 margin-left16" *ngIf="field.type == 'BASE_SALARY_POST_REVIEW' 
              && (isFieldValueValid(previewFormMap.get(container.economicMeasureType)!['baseSalaryPostReview']) 
              || (isFieldValueValid(errorTypesMap.get(container.economicMeasureType) || [])) )">
              {{isFieldValueValid(errorTypesMap.get(container.economicMeasureType) || [])
              ? '-'
              : (previewFormMap.get(container.economicMeasureType)!['baseSalaryPostReview'] | currency: 'EUR' :
              true:'1.2-2')}}</p>
            <p [ngClass]="{'disabled-text': !(isFieldValueValid(previewFormMap.get(container.economicMeasureType)!['equityAmountPostReview'])) &&
        !isFieldValueValid(errorTypesMap.get(container.economicMeasureType) || [])}"
              class="field-text margin-top20 margin-bottom4" *ngIf="field.type == 'EQUITY_POST_REVIEW'">
              {{(selectedProposalOptionType?.id == 'LEVEL_ADVANCEMENT_AND_ANNUAL_RETRIBUTION_INCREASE' &&
              container.economicMeasureType == 'ANNUAL_RETRIBUTION_INCREASE')
              ? ('proposal.EQUITY_POST_PL_ADVANCEMENT' |
              translate) : container.economicMeasureType == 'LEVEL_ADVANCEMENT' ? ('proposal.EQUITY_POST_REVIEW_PL' |
              translate) :
              'proposal.EQUITY_POST_REVIEW' | translate}}</p>
            <input *ngIf="field.type == 'EQUITY_POST_REVIEW' && 
                !(isFieldValueValid(previewFormMap.get(container.economicMeasureType)!['equityAmountPostReview'])) &&
                !isFieldValueValid(errorTypesMap.get(container.economicMeasureType) || [])"
              id="inputProposalForm-{{ field.type }}-{{container.economicMeasureType}}" [disabled]="true" type="text"
              placeholder="{{'proposal.INSERT_IMPORT' | translate}}" class="proposal-form-input disabled-input" />
            <p class="field-text-value margin-top20 margin-left16"
              *ngIf="field.type == 'EQUITY_POST_REVIEW' && 
                (isFieldValueValid(previewFormMap.get(container.economicMeasureType)!['equityAmountPostReview']) || (isFieldValueValid(errorTypesMap.get(container.economicMeasureType) || [])) )">
              {{isFieldValueValid(errorTypesMap.get(container.economicMeasureType) || [])
              ? '-'
              : ((previewFormMap.get(container.economicMeasureType)!['equityAmountPostReview'] | currency: 'EUR'
              :true:'1.2-2') +
              ((' (' + (previewFormMap.get(container.economicMeasureType)!['equityPercentagePostReview'] || '0') || ' ('
              + 0 ) + '%)'))}}
            </p>

            <div class="d-flex justify-content-between w-100 margin-top20 margin-bottom4"
              *ngIf="field.type == 'EXTRA_BUDGET_TYPE'">
              <p class="field-text">{{ "proposal.EXTRA_BUDGET_MOTIVATION" | translate }}</p>
              <div class="facultative-badge">
                <p>{{'proposal.OPTIONAL' | translate}}</p>
              </div>
            </div>
            <custom-select *ngIf="field.type == 'EXTRA_BUDGET_TYPE'" bindLabel="id"
              containerClass="cursor-pointer full-dimension custom-select gray-background"
              [bindModel]="selectedOptionExtraBudgetType.get(container.economicMeasureType)"
              [items]="extraBudgetTypeOptions" [labelForId]="'input-select-extra-budget-proposal-type'"
              [attrAriaLabel]="'generic.SELECT' | translate" bindLabel="label" [required]="false"
              [parameterNameOfSelectedItem]="'label'" placeholderLabel="{{'generic.SELECT' | translate}}" bindValue="id"
              (onSelectionChanged)="onSelectedExtraBudgetTypeOptionChanged($event, container.economicMeasureType)"
              [disabled]="false">
            </custom-select>
            <p *ngIf="field.type == 'EXTRA_BUDGET_TYPE'" class="detail-proposal-form-info-text margin-top2">
              {{"proposal.detailInfo.EXTRA_BUDGET_DESC" | translate}}
            </p>
            <div class="d-flex justify-content-between w-100 margin-top20 margin-bottom4"
              *ngIf="field.type == 'EXTRA_BUDGET_NOTES'">
              <p class="field-text">{{'proposal.NOTES_EXTRA_BUDGET' | translate}}</p>
              <div *ngIf="selectedOptionExtraBudgetType.size ? (selectedOptionExtraBudgetType.get(container.economicMeasureType) && 
          selectedOptionExtraBudgetType.get(container.economicMeasureType)?.id != 'OTHER') : true"
                class="facultative-badge">
                <p>{{'proposal.FACULTATIVE' | translate}}</p>
              </div>
            </div>
            <textarea *ngIf="field.type == 'EXTRA_BUDGET_NOTES'"
              [(ngModel)]="proposalFieldMap.get(container.economicMeasureType)![field.type]"
              [placeholder]="'proposal.INSERT_NOTE' | translate"
              attr.aria-label="{{ 'proposal.INSERT_NOTE' | translate }}"
              (ngModelChange)="onTextAreaChange(field.type, container.economicMeasureType, $event)"></textarea>
          </div>
          <div *ngIf="!last" class="container-divisor"></div>
        </div>
      </div>
      <!-- Note -->
      <div *ngIf="selectedProposalOptionType?.id"
        class="d-flex flex-column justify-content-between w-100 margin-top20 margin-bottom4 textarea-container">
        <div class="d-flex flex-row justify-content-between w-100 margin-bottom4">
          <p class="field-text">{{'proposal.NOTES' | translate}}</p>
          <div class="facultative-badge">
            <p>{{'proposal.FACULTATIVE' | translate}}</p>
          </div>
        </div>
        <textarea [(ngModel)]="proposalNote" [placeholder]="'proposal.INSERT_NOTE' | translate"
          attr.aria-label="{{ 'proposal.INSERT_NOTE' | translate }}"
          (ngModelChange)="onProposalNoteChange($event)"></textarea>
      </div>
      <!-- Salva proposata -->
      <div *ngIf="selectedProposalOptionType?.id" class="d-flex flex-column action-buttons-container">
        <button id="delete-propose-button" type="button"
          *ngIf="userProposalDetails?.currentProposal?.proposalType && userProposalDetails?.currentProposal?.statusType != 'DELETED'"
          (click)="deleteProposal()" class="btn btn-outline-dark no-border-btn btn-lg text-uppercase fw-bold">
          <p>{{ "generic.DELETE" | translate }}</p>
        </button>
        <button id="save-propose-button" type="button" [disabled]="saveDisabled()" (click)="savePropose()"
          class="btn btn-primary btn-lg text-uppercase fw-bold">
          <p>{{ "generic.SAVE" | translate }}</p>
        </button>
      </div>

      <!-- Storico interventi -->
      <div class="d-flex flex-column margin-top68">
        <p class="history-interventions-text fit-content" tooltipClass="custom-tooltip-class" placement="top"
          ngbTooltip="{{ 'proposal.INTERVENTIONS_HISTORY_TOOLTIP' | translate }}">{{ "proposal.INTERVENTIONS_HISTORY" |
          translate }}</p>
        <div class="line-divisor"></div>
        <!-- Storico valutazioni -->
        <div class="d-flex flex-row">
          <div class="evaluations-history-container">
            <div *ngFor="let evaluation of evaluationsHistory">
              <p tooltipClass="custom-tooltip-class" placement="top"
                [ngbTooltip]="('proposal.EVALUATION_HISTORY_TOOLTIP' | translate) + evaluation.year.toString()"
                class="year-text fit-content">{{ evaluation.yearLabel }}</p>
              <div *ngIf="evaluation.evaluation">
                <p class="normal-text fit-content">{{ ("proposal.evaluationNumber." + evaluation.evaluation) | translate
                  }}</p>
                <p class="normal-text fit-content">{{ ("proposal.evaluation." + evaluation.evaluation) | translate }}
                </p>
              </div>
              <p class="normal-text fit-content" *ngIf="!evaluation.evaluation">{{ "proposal.NO_EVALUATION" | translate
                }}</p>
            </div>
          </div>
          <div class="interventions-history-container">
            <div *ngFor="let intervention of interventionsHistory">
              <p tooltipClass="custom-tooltip-class" placement="top"
                [ngbTooltip]="('proposal.INTERVENTION_HISTORY_TOOLTIP' | translate) + intervention.year"
                class="year-text fit-content">{{ intervention.yearLabel }}</p>
              <div *ngFor="let interventionLabel of intervention.interventionsLabels"
                class="d-flex flex-column min-height0">
                <p tooltipClass="custom-tooltip-class" placement="top"
                  [ngbTooltip]="('proposal.historyInterventions.' + interventionLabel.type | translate)"
                  class="normal-text fit-content">
                  {{interventionLabel.label + ' ' + ( interventionLabel.amount | currency: 'EUR' : true:'1.2-2')}}</p>
              </div>
              <p class="normal-text fit-content"
                *ngIf="!intervention.interventionsLabels || !intervention.interventionsLabels.length">{{
                "proposal.NO_INTERVENTION" | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!isLoadingAnagUserDetails && activeTabProposalId == 'person-details'"
      class="anag-user-details-container d-flex flex-column">
      <div class="d-flex flex-row justify-content-between w-100 margin-bottom40">
        <div class="d-flex flex-column w-100">
          <p class="anag-user-title margin-bottom4">{{'proposal.user_anag_details.SOC_DISTACCO' | translate}}</p>
          <p class="anag-user-desc">{{anagUserDetails?.companyName || "-"}}</p>
        </div>
        <div class="d-flex flex-column w-100">
          <p class="anag-user-title margin-bottom4">{{'proposal.user_anag_details.ANZIANITY' | translate}}</p>
          <p class="anag-user-desc">{{anagUserDetails?.companySeniority == 1 ? "1 anno" :
            (anagUserDetails?.companySeniority || "0")}} anni</p>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-between w-100 margin-bottom40">
        <div class="d-flex flex-column w-100">
          <p class="anag-user-title margin-bottom4">{{'proposal.user_anag_details.EXECUTIVE_MANAGER' | translate}}</p>
          <p class="anag-user-desc">{{anagUserDetails?.executiveFullName || "-"}}</p>
        </div>
        <div class="d-flex flex-column w-100">
          <p class="anag-user-title margin-bottom4">{{'proposal.user_anag_details.PEOPLE_MANAGER' | translate}}</p>
          <p class="anag-user-desc">{{anagUserDetails?.managerFullName || "-"}}</p>
        </div>
      </div>
      <div class="d-flex flex-column w-100">
        <p class="anag-user-title margin-bottom4">Allowances</p>
        <p class="anag-user-desc">{{anagUserDetails?.allowancesLabel
          || ('proposal.user_anag_details.NO_INDENNITY' | translate)}}</p>
      </div>
    </div>
    <div *ngIf="!isLoadingTimelineDetails && activeTabProposalId == 'log-proposal'"
      class="timeline-details-container d-flex flex-column">
      <div class="d-flex flex-column">
        <timeline-proposal *ngFor="let timelineProposal of timelineDetails; let first = first; let last = last"
          [timelineProposal]="timelineProposal" [firstProposal]="first" [lastProposal]="last"></timeline-proposal>
      </div>
    </div>
    <loader *ngIf="isLoadingProposal || isLoadingAnagUserDetails || isLoadingTimelineDetails" [marginBlock]="'150px'">
    </loader>
  </div>
</ng-template>

<ng-template #filtersProposalSidebar let-offcanvas>
  <div class="offcanvas-header d-flex justify-content-between filter-header-container">

    <div class="d-flex flex-row align-items-start filters-header">
      <nga-icon src="assets/icons/filter-menu.svg" width="30" height="30"></nga-icon>
      <h4 id="content-sidebar-filter-title-text">{{'proposal.filter.TITLE' | translate }}</h4>
    </div>

    <div class="d-flex flex-row align-items-center cursor-pointer">
      <button type="button" class="btn icon-button" (click)="offcanvas.dismiss()" aria-label="Chiudi sidebar filtri">
        <img alt="" src="assets/icons/close.svg" />
      </button>
    </div>
  </div>

  <div class="offcanvas-body d-flex flex-column justify-content-start align-items-start">
    <ng-container *ngFor="let key of filtersKeys; let first = first; let index = index">
      <!-- Se è tra i filtri che questo utente può usare -->
      <ng-container *ngIf="filters.data[key].active">
        <!-- Contenitore filtro -->
        <div class="filter-item w-100 d-flex flex-column justify-content-start align-items-center"
          [ngClass]="{'first': first, 'opened': filters.data[key].isOpen, 'structure': key == 'structureIds'}">
          <!-- Header filtro -->
          <div class="w-100 d-flex flex-row justify-content-between align-items-center">
            <p> {{ 'proposal.filter.' + key | translate }}</p>
            <nga-button *ngIf="!filters.data[key].isOpen" [id]="'expand-filter-' + index" type="text" variant="primary"
              size="btn-sm" [ariaLabel]="'Espandi filtro'" [leftIconSrc]="'assets/icons/add.svg'"
              (onClick)="expandFilter(key)"></nga-button>

            <nga-button *ngIf="filters.data[key].isOpen" class="red-minus" type="text" variant="secondary" size="btn-sm"
              [ariaLabel]="'Riduci filtro'" [id]="'reduce-filter-' + index" [ariaLabel]="'Riduci filtro'"
              [leftIconSrc]="'assets/icons/minus.svg'" (onClick)="expandFilter(key)"></nga-button>
          </div>

          <!-- Filtro aperto -->
          <ng-container *ngIf="filters.data[key].isOpen">
            <ng-container *ngIf="isLoadingFilters; else filterContent">
              <div class="d-flex flex-row flex-wrap w-100 justify-content-center align-items-center loader-container">
                <loader width="30px"></loader>
              </div>
            </ng-container>
            <ng-template #filterContent>
              <!-- Filtri di tipo enum -->
              <ng-container *ngIf="enumFilterTypeKey.includes(key)">

                <div class="d-flex flex-row flex-wrap w-100 justify-content-start chips-container">
                  <ng-container *ngIf="filters.getValuesAsArray(key).length > 0; else noFilterAvailable">
                    <ng-container *ngFor="let value of filters.getValuesAsArray(key)">
                      <button class="filter-chip" (click)="filters.toggleStringFilter(key, value)"
                        [ngClass]="{'selected': filters.isSelected(key, value)}">
                        <ng-container *ngIf="key == 'stiCategory'; else enumChipLabel">
                          <p>{{ value }}</p>
                        </ng-container>
                        <ng-template #enumChipLabel>
                          <p>{{ 'proposal.filter.' + key + '.' + value | translate }}</p>
                        </ng-template>
                      </button>
                    </ng-container>
                  </ng-container>
                  <ng-template #noFilterAvailable>
                    <p class="no-filter-available">{{'proposal.filter.NO_FILTER_AVAILABLE' | translate}}</p>
                  </ng-template>
                </div>
              </ng-container>

              <!-- Filtri di tipo range -->
              <ng-container
                *ngIf="rangeFilterTypeKey.includes(key) && (key == 'age' || key == 'groupSeniority' || key == 'equity' || key == 'annualSalary' || key == 'lastMeasure')">
                <div class="d-flex flex-row w-100 justify-content-start gap-24 range-container">
                  <!-- Devo controllare le chiavi in modo specifico altrimenti non posso fare il 2wbinding 
                       negli input, perchè non riconoscerebbe il tipo corretto -->
                  <div class="d-flex flex-column w-100 justify-content-start align-items-start filter-range-input">
                    <p class="filter-input-label">{{'proposal.filter.' + key +'.FROM' | translate}}</p>

                    <input type="number" class="filter-input" [min]="filters.getValueMin(key)"
                      [max]="filters.getValueMax(key)" [placeholder]="filters.getValueMin(key)"
                      [(ngModel)]="filters.data[key].selected.min"
                      (change)="filters.updateRangeFilter(key, true, $event)">
                  </div>
                  <div class="d-flex flex-column w-100 justify-content-start align-items-start filter-range-input">

                    <p class="filter-input-label">{{'proposal.filter.' + key +'.TO' | translate}}</p>

                    <input type="number" class="filter-input" [min]="filters.getValueMin(key)"
                      [max]="filters.getValueMax(key)" [placeholder]="filters.getValueMax(key)"
                      [(ngModel)]="filters.data[key].selected.max"
                      (change)="filters.updateRangeFilter(key, false, $event)">
                  </div>
                </div>
              </ng-container>

              <!-- Filtri inquadramento / contractLevelIds-->
              <ng-container *ngIf="key == 'contractLevelIds'">
                <div class="d-flex flex-row flex-wrap w-100 justify-content-start chips-container">
                  <ng-container *ngIf="filters.getObjectValuesAsArray(key).length > 0; else noFilterAvailable">
                    <ng-container *ngFor="let value of filters.getObjectValuesAsArray(key)">
                      <button class="filter-chip" (click)="filters.toggleObjectFilter(key, value)"
                        [ngClass]="{'selected': filters.isObjectSelected(key, value)}">
                        <p tabindex="0">{{ value.name }}</p>
                      </button>
                    </ng-container>
                  </ng-container>
                  <ng-template #noFilterAvailable>
                    <p class="no-filter-available">{{'proposal.filter.NO_FILTER_AVAILABLE' | translate}}</p>
                  </ng-template>
                </div>
              </ng-container>

              <ng-container *ngIf="key == 'structureIds'">
                <div class="d-flex flex-row flex-wrap w-100 justify-content-start p-select-wrapper" #multiselectBody>
                  <p-floatLabel>
                    <p-multiSelect [options]="filters.data.structureIds.values" [overlayVisible]="true"
                      [appendTo]="multiselectBody" [showToggleAll]="false"
                      [(ngModel)]="filters.data.structureIds.selected" optionLabel="name"
                      [filterPlaceHolder]="'proposal.actions.SEARCH' | translate"
                      placeholder="{{ 'proposal.filter.structureIds.CHOOSE' | translate}}"
                      (onChange)="onMultiSelectChange($event)" />
                    <label for="float-label">{{'proposal.filter.structureIds.CHOOSE' | translate}}</label>
                  </p-floatLabel>
                </div>
              </ng-container>

              <!-- Filtri composti con chips + range (stiDetails, lastMeasure)-->
              <ng-container *ngIf="comboFilterTypeKey.includes(key)">
                <div class="d-flex flex-column w-100 justify-content-start gap-20 margin-top-20">
                  <!-- Sezione scelta chips-->
                  <p *ngIf="key != 'stiCategory'" class="filter-subtitle">{{"proposal.filter."+ key + ".CHOOSE_TYPE" |
                    translate}}</p>
                  <div class="d-flex flex-row flex-wrap w-100 justify-content-start chips-container combo">
                    <ng-container *ngIf="filters.getComboValuesAsArray(key).length > 0; else noFilterAvailable">
                      <ng-container *ngFor="let value of filters.getComboValuesAsArray(key)">
                        <button class="filter-chip" (click)="filters.toggleComboFilter(key, value)"
                          [ngClass]="{'selected': filters.isComboSelected(key, value)}">
                          <p>{{ value.name }}</p>
                        </button>
                      </ng-container>
                    </ng-container>
                    <ng-template #noFilterAvailable>
                      <p class="no-filter-available">{{'proposal.filter.NO_FILTER_AVAILABLE' | translate}}</p>
                    </ng-template>
                  </div>
                  <!-- sezione range amount -->
                  <div class="d-flex flex-column w-100 justify-content-start gap-8"
                    *ngIf="key =='lastMeasure' || key == 'stiDetails'">
                    <!-- Range per stiDetails -->
                    <ng-container *ngIf="key == 'stiDetails'">
                      <p class="filter-subtitle">{{"proposal.filter.stiDetails.CHOOSE_RANGE" | translate}}</p>
                      <!-- Range per amount -->
                      <div class="d-flex flex-row w-100 justify-content-start gap-24 range-container combo">
                        <div
                          class="d-flex flex-column w-100 justify-content-start align-items-start filter-range-input">
                          <p class="filter-input-label">{{'proposal.filter.stiDetails.FROM_AMOUNT' | translate}}</p>
                          <input type="number" class="filter-input" [min]="filters.getComboValue(key, 'minAmount')"
                            [disabled]="filters.getComboValue(key, 'minAmount') == null || !filters.checkComboAmountSelected()"
                            [max]="filters.getComboValue(key, 'maxAmount')"
                            [placeholder]="filters.getComboValue(key, 'minAmount') == null ? '-' : filters.getComboValue(key, 'minAmount')"
                            [(ngModel)]="filters.data[key].selected.minAmount"
                            (change)="filters.updateComboRangeFilter(key, 'minAmount', $event)">
                        </div>
                        <div
                          class="d-flex flex-column w-100 justify-content-start align-items-start filter-range-input">
                          <p class="filter-input-label">{{'proposal.filter.stiDetails.TO_AMOUNT' | translate}}</p>
                          <input type="number" class="filter-input" [min]="filters.getComboValue(key, 'minAmount')"
                            [max]="filters.getComboValue(key, 'maxAmount')"
                            [placeholder]="filters.getComboValue(key, 'maxAmount') == null ? '-' : filters.getComboValue(key, 'maxAmount')"
                            [disabled]="filters.getComboValue(key, 'maxAmount') == null || !filters.checkComboAmountSelected()"
                            [(ngModel)]="filters.data[key].selected.maxAmount"
                            (change)="filters.updateComboRangeFilter(key, 'maxAmount', $event)">
                        </div>
                      </div>
                      <!-- Tange per percentuale -->
                      <div class="d-flex flex-row w-100 justify-content-start gap-24 range-container combo">
                        <div
                          class="d-flex flex-column w-100 justify-content-start align-items-start filter-range-input">
                          <p class="filter-input-label">{{'proposal.filter.stiDetails.FROM_PERC' | translate}}</p>
                          <input type="number" class="filter-input" [min]="filters.getComboValue(key, 'minPerc')"
                            [max]="filters.getComboValue(key, 'maxPerc')"
                            [disabled]="filters.getComboValue(key, 'minPerc') == null || !filters.checkComboPercSelected()"
                            [placeholder]="filters.getComboValue(key, 'minPerc') == null ? '-' : filters.getComboValue(key, 'minPerc')"
                            [(ngModel)]="filters.data[key].selected.minPerc"
                            (change)="filters.updateComboRangeFilter(key, 'minPerc', $event)">
                        </div>
                        <div
                          class="d-flex flex-column w-100 justify-content-start align-items-start filter-range-input">
                          <p class="filter-input-label">{{'proposal.filter.stiDetails.TO_PERC' | translate}}</p>
                          <input type="number" class="filter-input" [min]="filters.getComboValue(key, 'minPerc')"
                            [max]="filters.getComboValue(key, 'maxPerc')"
                            [placeholder]="filters.getComboValue(key, 'maxPerc') == null ? '-' : filters.getComboValue(key, 'maxPerc')"
                            [disabled]="filters.getComboValue(key, 'maxPerc') == null || !filters.checkComboPercSelected()"
                            [(ngModel)]="filters.data[key].selected.maxPerc"
                            (change)="filters.updateComboRangeFilter(key, 'maxPerc', $event)">
                        </div>
                      </div>
                    </ng-container>

                    <!-- Range per lastMeasure -->
                    <ng-container *ngIf="key == 'lastMeasure'">
                      <p class="filter-subtitle">{{"proposal.filter.lastMeasure.CHOOSE_RANGE" | translate}}</p>

                      <div class="d-flex flex-row w-100 justify-content-start gap-24 range-container">
                        <div
                          class="d-flex flex-column w-100 justify-content-start align-items-start filter-range-input">
                          <p class="filter-input-label">{{'proposal.filter.lastMeasure.FROM' | translate}}</p>
                          <input type="number" class="filter-input" [min]="filters.getComboValue(key, 'min')"
                            [max]="filters.getComboValue(key, 'max')" [placeholder]="filters.getComboValue(key, 'min')"
                            [(ngModel)]="filters.data.lastMeasure.selected.min"
                            (change)="filters.updateComboRangeFilter(key, 'min', $event)">
                        </div>
                        <div
                          class="d-flex flex-column w-100 justify-content-start align-items-start filter-range-input">
                          <p class="filter-input-label">{{'proposal.filter.lastMeasure.TO' | translate}}</p>
                          <input type="number" class="filter-input" [min]="filters.getComboValue(key, 'min')"
                            [max]="filters.getComboValue(key, 'max')" [placeholder]="filters.getComboValue(key, 'max')"
                            [(ngModel)]="filters.data.lastMeasure.selected.max"
                            (change)="filters.updateComboRangeFilter(key, 'max', $event)">
                        </div>
                      </div>
                    </ng-container>

                  </div>
                </div>
              </ng-container>
            </ng-template>

          </ng-container>
        </div>


      </ng-container>
    </ng-container>
  </div>

  <div class="d-flex flex-column justify-content-center align-items-center apply-filter-shadow">
    <nga-button [id]="'apply-filters'" variant="secondary"
      [label]="('proposal.filter.APPLY' | translate | uppercase) || null" (click)="closeFiltersSidebar(true)">
    </nga-button>
    <nga-button [id]="'reset-filters'" [label]="('proposal.filter.RESET' | translate) || null" type="text"
      variant="secondary" (click)="filters.resetFilter(); closeFiltersSidebar()">
    </nga-button>
  </div>
</ng-template>



<!-- Sidebar gerarchie -->
<ng-template #hierarchySidebar let-offcanvas>
  <div class="offcanvas-header d-flex justify-content-between header-container">

    <div class="d-flex flex-row align-items-start header">
      <nga-icon src="assets/icons/hierarchy.svg" width="30" height="30"></nga-icon>
      <h4 id="content-sidebar-hierarchy-title-text">{{'proposal.hierarchy.TITLE' | translate }}</h4>
    </div>

    <div class="d-flex flex-row align-items-center cursor-pointer">
      <button type="button" class="btn icon-button" aria-label="Close" (click)="offcanvas.dismiss()"
        aria-label="Chiudi sidebar gerarchie">
        <img alt="" src="assets/icons/close.svg" />
      </button>
    </div>
  </div>
  <h4 class="offcanvas-subtitle">{{ ('proposal.hierarchy.SUTITLE'| translate) + ' ' +
    getSelectedNodeIds(this.treeFormattedData).length}}</h4>

  <div class="offcanvas-body d-flex flex-column justify-content-start align-items-start scroll-y-container">
    <!-- Albero gerarchie -->
    <ng-container *ngIf="!isLoadingHierarchyData && hierarchyData; else hierarchyLoader">
      <h-tree-node class="w-100" [nodes]="treeFormattedData"></h-tree-node>
    </ng-container>

    <ng-template #hierarchyLoader>
      <loader [marginBlock]="'150px'" [width]="'80px'"></loader>
    </ng-template>
  </div>

  <div class="d-flex flex-column justify-content-center align-items-center apply-filter-shadow">
    <nga-button [id]="'apply-hierarchy-filters'" [label]="('proposal.filter.APPLY' | translate | uppercase) || null"
      variant="secondary" (click)="applyHierarchyFilter()">
    </nga-button>
    <nga-button [id]="'reset-hierarchy-filters'" [label]="('proposal.filter.RESET' | translate) || null" type="text"
      variant="secondary" (click)="resetHierarchyFilter(treeFormattedData)">
    </nga-button>
  </div>

</ng-template>

<modal id="modal-confirm-send-proposal" title="{{ 'proposal.modal.SEND_TITLE' | translate }}"
  confirmButton="{{ 'proposal.actions.CONFIRM' | translate }}" cancelButton="{{ 'generic.BACK' | translate }}"
  (onCancel)="closeConfirmSendProposalModal()" (onClose)="closeConfirmSendProposalModal()"
  (onConfirm)="closeConfirmSendProposalModal(true)">
  <ng-container *ngFor="let proposal of proposalsToBeConfirmed">
    <p>{{ proposal.forename + " " + proposal.surname + " - " + ("proposal.types." + proposal.proposalType | translate) +
      " " + (proposal.totalAmount ? (proposal.totalAmount | currency: 'EUR' : true:'1.2-2') : "")}}</p>
  </ng-container>
</modal>