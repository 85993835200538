import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Renderer2, AfterViewInit, ElementRef } from "@angular/core";
import { DropdownPosition } from "@ng-select/ng-select";
import { Subject } from "rxjs";
import { User } from "salary-review-cl";

@Component({
  selector: "custom-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"]
})
export class SelectComponent implements OnInit, AfterViewInit {
  @Input() labelForId: string = "";
  @Input() formTitle?: string;
  @Input() parameterNameOfSelectedItem?: string;
  @Input() typetosearch?: string;
  @Input() noDataFoundLabel?: string;
  @Input() startSearchLabel?: string;
  @Input() avatarImageAccessibilityText?: string;
  @Input() loadingData: string | null = null;
  @Input() placeholderLabel?: string;
  @Input() disabled: boolean = false;
  @Input() searchable: boolean = false;
  @Input() required: boolean = false;
  @Input() virtualScroll: boolean = false;
  @Input() bindValue!: string;
  @Input() bindModel?: unknown;
  @Input() getSelectedItemCustomTitle?: () => string;
  @Input() containerClass?: string | string[];
  @Input() label: string | null = null;
  @Input() ariaLabelText: string | null = null;
  @Input() idForInput: string | null = null;
  @Input() addTag: boolean = false;
  @Input() loading: boolean = false;
  @Input() clearOnBackspace: boolean = false;
  @Input() hideSelected: boolean = false;
  @Input() typeahead!: Subject<string>;
  @Input() multiple: boolean = false;
  @Input() areUsersList: boolean = false;
  @Input() areGroupsList: boolean = false;
  @Input() areMailAndNameList: boolean = false;
  @Input() areMailtemplateList: boolean = false;
  @Input() bindLabel: string | null = null;
  @Input() dropdownPosition: DropdownPosition = "bottom";
  @Input() lables: { id: string, text: string }[] = [];
  @Input() selectClass: string | null = null;
  @Input() inputWrapperClass?: string;
  @Input() noWhitespace?: string;
  @Output() addItem = new EventEmitter();
  @Output() onBindModelChanged = new EventEmitter();
  @Output() onSelectionChanged = new EventEmitter();
  @Output() removeItem = new EventEmitter();
  @Output() scrollToEnd = new EventEmitter<unknown>();
  @Output() close = new EventEmitter();
  @Input() errorRequiredText?: string;
  @Input() forceShowError: boolean = false;
  @Input() attrAriaLabel?: string;
  @Input() weightSelection: boolean = false;
  @Input() markFirst: boolean = false;
  // lista dei risultati
  @Input() items: SelectItem[] | number[] | string[] | unknown[] = [];
  @Input() ieFlexProblem: boolean = false;
  @Input() selectTrainingPassportClass: boolean = false;
  @Input() isFacultySelect: boolean = false;
  translationAriaLabel: string | null = null;
  @Input() isIDPCategorySelect: boolean = false;
  @Input() placeholder: string = "";
  @ViewChild("alloySelect") alloySelect!: { searchInput: ElementRef<HTMLElement> };

  @Output() onSearch: EventEmitter<unknown> = new EventEmitter<unknown>();
  filter: string | null = null;
  searchTimeout: number | null = null;
  resetResultList: boolean = false;
  focused: boolean = false;

  constructor(
    private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.labelForId;
    this.attrAriaLabel;
  }

  resolveItemTitle(item: SelectItem, isForSelectedItem?: boolean): string | number | undefined {
    if (this.weightSelection && isForSelectedItem) {
      return item.title + " (" + (item.attributeWeight ?? 0) + ")";
    } else if (this.lables?.length) {
      for (let i = 0; i < this.lables.length; i++) {
        if (this.lables[i].id == item) {
          return this.bindLabel ? this.lables[i].text : this.lables[i].text;
        }
      }
    } else if (this.parameterNameOfSelectedItem) {
      return item[this.parameterNameOfSelectedItem as keyof SelectItem] as string | number;
    } else if (this.areGroupsList) {
      return item.title;
    } else if (typeof item === "string" || typeof item === "number") {
      return item;
    } else {
      return item.name ?? item.title;
    }
    return undefined;
  }

  ngAfterViewInit() {
    if (this.attrAriaLabel) {
      setTimeout(() => {
        if (this.alloySelect?.searchInput) {

          this.renderer.setAttribute(this.alloySelect.searchInput.nativeElement, "aria-label", this.attrAriaLabel ?? "");
          this.attrAriaLabel ? this.renderer.setAttribute(this.alloySelect.searchInput.nativeElement, "aria-label", this.attrAriaLabel) : null;
          this.attrAriaLabel ? this.renderer.setAttribute(this.alloySelect.searchInput.nativeElement.offsetParent, "aria-label", this.attrAriaLabel) : null;
          // this.renderer.setAttribute(this.alloySelect.searchInput.nativeElement, "aria-required", this.required.toString());
          this.renderer.setAttribute(this.alloySelect.searchInput.nativeElement, "required", this.required.toString());
          this.labelForId ? this.renderer.setAttribute(this.alloySelect.searchInput.nativeElement, "name", this.labelForId || "") : null;
        }
      });
    }

  }

  compareWithFn(item: SelectItem, selected: SelectItem) {
    if (selected && item && selected && item.userId) {
      return item.userId === selected;
    } else if (selected && item && selected && item.textTemplateId) {
      return item.textTemplateId === selected;
    } else if (selected && item && selected && item.managerId) {
      return item.managerId === selected;
    } else if (selected && item?.tagId && item.tagType && (item.tagType === "TECH_COMPETENCES" || item.tagType === "SOFT_COMPETENCES")) {
      // Selezione di un peso, quindi sono in una lista di tag; pertanto, paragono i loro id
      return item.tagId === selected;
    } else {
      return item === selected;
    }
  }

  emitRemoveItem(event: unknown): void {
    this.removeItem.emit(event);
  }

  emitAddItem(selectedTag: unknown): void {
    if (this.addItem && this.addItem instanceof EventEmitter) {
      this.addItem.emit(selectedTag);
    }
  }

  emitOnClose(): void {
    this.focused = true;
    if (this.close && this.close instanceof EventEmitter) {
      this.close.emit();
    }
  }

  emitOnSelectionChanged(data: unknown): void {
    if (this.onSelectionChanged && this.onSelectionChanged instanceof EventEmitter) {
      this.onSelectionChanged.emit(data);
    }
  }

  emiOnModelChanged(data?: unknown): void {
    if (this.onBindModelChanged && this.onBindModelChanged instanceof EventEmitter) {
      this.onBindModelChanged.emit(data);
    }
  }

  emitOnScrollToEnd(data?: unknown): void {
    if (this.scrollToEnd && this.scrollToEnd instanceof EventEmitter) {
      this.scrollToEnd.emit(data);
    }
  }

  isAdminSelect() {
    return this.containerClass && this.containerClass.indexOf("admin-select") >= 0;
  }

  // Torna l'avatar di un utente
  getUserAvatar(selectedUser: User): string {
    return (selectedUser?.userOptions?.avatarImage) ?? (selectedUser && selectedUser && selectedUser.chiaveSesso == "M" ? "assets/img/icons/placeholder.svg" : "assets/img/icons/placeholder_female.svg");
  }


  emitOnSearch(searchTerm: { term: string, items: unknown }): void {
    this.filter = searchTerm.term;
    // this.onSearch.emit(this.filter);
  }
}

interface SelectItem {
  userId?: string;
  textTemplateId?: string;
  managerId?: string;
  tagId?: string;
  tagType?: string;
  attributeWeight?: number;
  title?: string;
  name?: string;
}
