<div
    class="w-100 d-flex flex-column justify-content-center align-items-end align-self-stretch proposal-operation-container">

    <div class="d-flex flex-row align-items-center status"
        *ngIf="data && (data?.proposalEconomicsDetails?.length || data.proposalType == 'EXECUTIVE_PROMOTION' || data.proposalType == 'OFFICER_PROMOTION')">
        <div class="status-dot" [ngClass]="data.statusType"></div>
        <div class="d-flex flex-column justify-content-center align-items-start">
            <p class="status-text">{{ data.lastUpdateDate | date : 'dd/MM/yyyy'}}</p>
            <p class="status-text"><b>{{ 'proposal.table.action.status.' + data.statusType | translate }} - {{
                    data.proposerUserSurename}}</b></p>
        </div>
    </div>

    <div class="d-flex flex-row justify-content-end align-items-center">

        <ng-container *ngIf="canEdit">
            <!-- Se non ci sono ancora proposte inserite -->
            <!-- *ngIf="((!data.proposalEconomicsDetails?.length && !data.proposalType)) || (phaseInfo?.canConfirm &&  (!data && teamType != 'EXTENDED') || (data && data.statusType == 'DELETED'))"-->
            <nga-button role="button"
                *ngIf="((!data?.proposalEconomicsDetails?.length && !data?.proposalType)) || (data?.statusType == 'DELETED')"
                [id]="id" type="text" variant="secondary" label="Proponi" rightIconSrc="assets/icons/proposal-edit.svg"
                (onClick)="onProposeClicked()">
            </nga-button>
        </ng-container>

        <ng-container *ngIf="!canEdit && (!data || (data && !data.canEdit))">
            <!-- "nessuna proposta" -->
            <div *ngIf="(!data && teamType == 'EXTENDED') || (!data && !phaseInfo?.canConfirm) || (data && !data.canEdit && !data.proposalEconomicsDetails?.length && !data.proposalType) || (data && !data.canEdit && data.statusType== 'DELETED')"
                class="d-flex flex-row justify-content-end align-items-center gap-8 no-proposal">
                <p>{{ 'proposal.table.measures.NO_PROPOSAL' | translate }}</p>
                <nga-icon src="assets/icons/lock-closed.svg" type="svg">
                </nga-icon>
            </div>
        </ng-container>


        <!-- Se ci sono proposte inserite -->
        <ng-container *ngIf="data && data?.statusType != 'DELETED'">
            <!-- contenitore recap-->
            <div class="d-flex flex-row justify-content-end align-items-center gap-8">

                <div class="d-flex flex-column justify-content-center align-items-end">
                    <ng-container
                        *ngIf="data.proposalType == 'EXECUTIVE_PROMOTION' || data.proposalType == 'OFFICER_PROMOTION'">
                        <p class="intervention"
                            [ngClass]="{'draft': data.statusType == 'DRAFT' || (data.statusType == 'PROPOSED' && data.canEdit)}">
                            {{ 'proposal.table.measures.' + data.proposalType | translate}}
                        </p>
                    </ng-container>

                    <ng-container *ngIf="data?.proposalEconomicsDetails?.length">
                        <p *ngFor="let proposal of data?.proposalEconomicsDetails" class="intervention"
                            [ngClass]="{'draft': data.statusType == 'DRAFT' || (data.statusType == 'PROPOSED' && data.canEdit)}">
                            {{ 'proposal.table.measures.' + proposal.proposalEconomicMeasureType | translate}}

                            <ng-container
                                *ngIf="proposal.proposalEconomicMeasureType == 'STI_UPDATE' && proposal.percentage; else amount">
                                {{ proposal.percentage }}%
                            </ng-container>

                            <ng-template #amount>
                                {{ proposal.amount | currency: 'EUR' : true:'1.2-2'}}
                            </ng-template>

                            <img class="proposal-operation-warning" *ngIf="proposal.showWarning" aria-hidden="true"
                                src="assets/icons/warning.svg" alt="">
                        </p>
                    </ng-container>
                </div>

                <button *ngIf="data.canEdit" (click)="onProposeClicked()" class="button-icon-edit">
                    <nga-icon class="edit-size" src="assets/icons/proposal-edit.svg"></nga-icon>
                </button>

                <nga-icon *ngIf="!data.canEdit" src="assets/icons/lock-closed.svg">
                </nga-icon>
            </div>
        </ng-container>

    </div>
</div>