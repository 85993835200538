<ng-container *ngIf="tableData != null">
    <table>
        <!-- Header con budget assegnato -->
        <tr class="header">
            <th>{{ "proposal.dashboard.expanded.table.budget.HEADER" | translate }}</th>
            <th class="right">{{ tableData.totalBudget | currency: 'EUR':true:'1.0' }} </th>
        </tr>
        <!-- Voci uscite budget -->
        <ng-container *ngFor="let detail of tableData.proposalTypesUsedBudgets">
            <tr
                *ngIf="processType == 'STANDARD' || (processType =='EXECUTIVE' && detail.proposalEconomicMeasureType != 'LEVEL_ADVANCEMENT')">
                <td class="cell-container">
                    <div class="dot" [ngClass]="detail.proposalEconomicMeasureType"></div>
                    {{ ("proposal.dashboardTypes." + detail.proposalEconomicMeasureType) | translate}}
                </td>
                <td class="right">{{ (detail.amount || 0) | currency: 'EUR':true:'1.0'}}</td>
            </tr>
        </ng-container>
        <!-- Budget residuo -->
        <tr>
            <td class="cell-container">
                <div class="dot" [ngClass]="type == 'UNA_TANTUM' ? 'RESIDUAL_TANTUM' : 'RESIDUAL'"></div>
                {{ ("proposal.dashboard.expanded.table.budget.RESIDUAL") | translate}}
            </td>
            <td class="right" [ngClass]="{'warning': tableData.showAvailableBudgetWarning}">
                {{ (tableData.availableBudget || 0) | currency: 'EUR':true:'1.0'}}
                <ng-container *ngIf="tableData.showAvailableBudgetWarning">
                    <img *ngIf="tableData.showAvailableBudgetWarning" aria-hidden="true" src="assets/icons/warning.svg"
                        alt="">
                </ng-container>
            </td>
        </tr>
    </table>
</ng-container>