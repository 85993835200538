<div class="d-flex w-100 align-items-center dashboard-container"
    [ngClass]="{'expanded': isExpanded, 'gender': activeTab == 'GENDER'}">
    <!-- Dashboard minimizzata -->
    <ng-container *ngIf="!isExpanded">
        <ng-container *ngIf="summary != null">
            <!-- Dashboard budget -->
            <ng-container *ngIf="summary?.budgetSummaries?.length">
                <div class="d-flex align-items-center minimized">
                    <!-- Permanente -->
                    <div class="d-flex flex-column align-items-start indicator"
                        *ngFor="let item of summary.budgetSummaries">
                        <!-- Titoli indicatore per processo dirigenti -->
                        <ng-container *ngIf="processType == 'EXECUTIVE'; else standardProcess">
                            <label class="title">{{ ('proposal.dashboard.budgetSummary.' + item.budgetType +
                                '_EXECUTIVE') |
                                translate}}</label>
                        </ng-container>
                        <!-- Titoli indicatore per processo standard -->
                        <ng-template #standardProcess>
                            <label class="title">{{ ('proposal.dashboard.budgetSummary.' + item.budgetType) |
                                translate}}</label>
                        </ng-template>
                        <span>
                            <p [ngClass]="{'warning': item.showWarning}">
                                {{item.usedBudget | currency:'EUR':true:'1.0'}}
                            </p>
                            <img *ngIf="item.showWarning" aria-hidden="true" src="../../../../assets/icons/warning.svg"
                                alt="">
                            {{'/ ' + (item.totalBudget | currency: 'EUR':true:'1.0')}}
                        </span>
                    </div>
                </div>
            </ng-container>
            <!-- Divisore -->
            <ng-container *ngIf="summary?.budgetSummaries?.length && summary?.genderSummaries?.length">
                <div class="v-divider"></div>
            </ng-container>
            <!-- Dashboard genere -->
            <ng-container *ngIf="summary?.genderSummaries?.length">
                <div class="d-flex align-items-center minimized"
                    *ngFor="let item of summary.genderSummaries; let i = index">
                    <!-- Indicatore popolazione donne -->
                    <div class="indicator">
                        <label class="title">
                            {{ ('proposal.dashboard.genderSummary.FEMALE') | translate}}
                        </label>
                        <span>{{item.totUsers + ' (' + ((item.totUsersPercentage || 0) | number: '1.0-2' ) + '%)'}}
                            <!--img *ngIf="item.showWarning" aria-hidden="true" src="assets/icons/without_default_model.svg" alt=""-->
                        </span>
                    </div>
                    <!-- Indicatore aumenti ral donne -->
                    <div class="indicator">
                        <label class="title">{{ ('proposal.dashboard.genderSummary.MALE') |
                            translate}}</label>
                        <span [ngClass]="{'warning': item.showWarning}">
                            {{item.totAnnualSalaryIncreaseAssigned + ' (' +
                            ((item.totAnnualSalaryIncreaseAssignedPercentage || 0) | number:
                            '1.0-2' ) + '%)'}}
                            <img *ngIf="item.showWarning" aria-hidden="true" src="assets/icons/warning.svg" alt="">
                        </span>
                    </div>

                </div>
            </ng-container>
            <button type="button" class="btn expand-action" (click)="onExpandClick()" aria-label="Espandi dashboard">
                <img aria-hidden="true" src="assets/icons/expand.svg" alt="">
            </button>
        </ng-container>
    </ng-container>

    <!-- Dashboard estesa -->
    <ng-container *ngIf="isExpanded">
        <ng-container *ngIf="summary != null">
            <div class="d-flex align-items-start w-100 expanded-container">
                <div class="d-flex flex-column align-items-start expanded-tabs"
                    *ngIf="hasBudgetExtendedData() && hasGenderExtendedData()" aria-label="Basic example">
                    <!-- Budget esteso-->
                    <button class="btn dashboard-button" [ngClass]="{'active': activeTab == 'BUDGET'}"
                        (click)="showExpandedTab('BUDGET')">
                        {{'proposal.dashboard.expanded.BUDGET_TAB' | translate}}
                    </button>
                    <!-- Gender esteso -->
                    <button class="btn dashboard-button" [ngClass]="{'active': activeTab == 'GENDER'}"
                        (click)="showExpandedTab('GENDER')">
                        {{'proposal.dashboard.expanded.GENDER_TAB' | translate}}
                    </button>
                </div>
                <!-- Contenuto dashboard estesa -->
                <div class="expanded-content" *ngIf="!isLoadingExpanded; else expandedDashboardLoading"
                    [ngClass]="{'single-tab': !(hasBudgetExtendedData() && hasGenderExtendedData())}">

                    <div class="d-flex flex-row justify-content-start expanded-section-container"
                        [ngClass]="{'single-tab': hasBudgetExtendedData() || hasGenderExtendedData()}">
                        <!-- Contenuto budget esteso -->
                        <ng-container *ngIf="activeTab == 'BUDGET'">
                            <ng-container *ngIf="budgetExtendedData != null">
                                <ng-container *ngFor="let item of budgetExtendedData; let last = last">
                                    <div class="d-flex flex-column align-items-start expanded-section">
                                        <h4>
                                            {{ ("proposal.dashboard.expanded.table." + item.budgetType + '_' +
                                            processType) | translate }}
                                        </h4>
                                        <proposal-table-dots-budget [tableData]="item || null"
                                            [processType]="processType">
                                        </proposal-table-dots-budget>
                                    </div>
                                    <div *ngIf="!last && budgetExtendedData.length > 1" class="v-divider"></div>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                        <!-- Contenuto genere esteso -->
                        <ng-container *ngIf="activeTab == 'GENDER'">
                            <!-- Recap popolazione -->
                            <div class="expanded-section no-grow d-flex flex-column justify-content-start">
                                <h4>{{ ("proposal.dashboard.expanded.gender.HEADER") | translate}}</h4>
                                <proposal-table-dots-gender [tableData]="genderExtendedData">
                                </proposal-table-dots-gender>
                            </div>
                            <div class="v-divider"></div>

                            <!-- Importo e numero proposte -->
                            <div class="expanded-section gender-section">
                                <proposal-table-gender [tableData]="genderExtendedData?.genderTypesWithDetails ?? null"
                                    [processType]="processType">
                                </proposal-table-gender>
                            </div>
                        </ng-container>
                    </div>

                    <!-- Riduci dashboard -->
                    <button type="button" class="btn expand-action no-grow" (click)="onExpandClick()"
                        aria-label="Riduci dashboard">
                        <img aria-hidden="true" src="assets/icons/reduce.svg" alt="">
                    </button>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>

<ng-template #dashboardLoading>
    <div class="loader-container">
        <loader [width]="'45px'"></loader>
    </div>
</ng-template>

<ng-template #expandedDashboardLoading>
    <div class="loader-container">
        <loader [width]="'60px'"></loader>
    </div>
</ng-template>
