import { inject, Injectable } from "@angular/core";

import { LoggerService } from "./logger.service";
import { ApplicationError, ClientError, HTTPError } from "./exception.service";
import { GeneralConfig, EnvironmentLoaderService } from "src/ancestors/env-config.service";
import { ApplicationHttpResponse } from "sharedclasses";
import { BaseLoginInfo } from "src/app/global-service/user.service";

export type RequestMethod = "get" | "post" | "put" | "delete";

export type ApplicationResponse<T> = [T | undefined, ClientError | undefined];

export namespace API_REQUEST_METHODS {
  export const GET = "get";
  export const POST = "post";
  export const PUT = "put";
  export const DELETE = "delete";
}

export interface DownloadedFile {
  fileName: string;
  fileUrl: string;
}

export interface FetchArguments {
  method?: RequestMethod,
  url?: string,
  path?: string[];
  query?: Record<string, unknown> | object;
  body?: Record<string, unknown> | object;
  header?: Record<string, string> | object;
  timeout?: number;
  retry?: number;
  formData?: boolean;
  preventFastFlickering?: boolean;
  minimum?: number;
  isFileDownload?: boolean;
  withPossibleResponseNull?: boolean
}

@Injectable({ providedIn: "root" })
export abstract class HttpService {
  constructor() {
    this.logger.setCaller("HttpService");
  }
  protected envConfig: EnvironmentLoaderService = inject(EnvironmentLoaderService);
  protected logger: LoggerService = inject(LoggerService);
  protected env: GeneralConfig = this.envConfig.getEnvConfig();
  protected tokenKey: string = this.env.localTokenKey;
  protected flickeringTimeout?: ReturnType<typeof setTimeout>;
  protected instance?: {
    url?: string;
    header?: Record<string, string>;
    preventFastFlickering?: boolean;
    minimum?: number;
  };

  /**
   * Setta nel service alcune informazioni per eseguire le chiamate HTTP,
   * senza doverle riportare ogni volta nei parametri della richiesta
   * @param instance 
   */
  protected createInstance(instance: {
    url?: string;
    header?: Record<string, string>;
    preventFastFlickering?: boolean;
    minimum?: number;
  }) {
    this.instance = {
      header: instance.header,
      url: instance.url,
      preventFastFlickering: instance.preventFastFlickering,
      minimum: instance.minimum
    };
  }
  /**
   * 
   * Fa una chiamata REST, aggiungendo tutto quello che serve (token nell'header se esiste, urlencode, ecc..)
   * 
   * @param method Tipo di chiamata (get, post, put, delete, ecc...)
   * @param baseURL Url di base
   * @param serviceName Servizio da chiamare (completa il path combinandosi con il baseURL)
   * @param params Parametri da passare alla chiamata (è un oggetto che può contenere urlParam: string[], queryParam: {[key: string]: string}, bodyParam: {[key: string]: string} | string )
   * @param options Opzioni aggiuntive (timeout, tentativi)
   */
  protected async httpClient<T>(service: string, fetchArguments?: FetchArguments): Promise<T | undefined> {
    const { method, body, header, url, query: queryParam, path: pathParam, retry, formData, timeout, preventFastFlickering, minimum, isFileDownload, withPossibleResponseNull } = fetchArguments ?? {};
    
    /** Prepara il path e gli headers */
    const path = this.createPath(url ?? this.instance?.url ?? "", service, queryParam, pathParam);
    let headers = this.createHeaders(header, formData);

    if (this.instance?.header) {
      const headersFromInstance = this.createHeaders(this.instance.header, formData);
      headers = { ...headersFromInstance, ...headers };
    }

    if (!path) {
      throw new Error("Url mancante");
    }

    try {
      const applicationHttpResponse = await this.prepareHttpRequest<T>(path, headers, method, body, formData, timeout, retry, preventFastFlickering, minimum, isFileDownload);
  
      if (!withPossibleResponseNull && (applicationHttpResponse?.response === null || applicationHttpResponse?.response === undefined)) {
        throw new ClientError(applicationHttpResponse);
      }
      
      return applicationHttpResponse.response;
      
    } catch (error: unknown) {
      
      throw new ApplicationError(error, method ?? API_REQUEST_METHODS.GET, service);
    
    }
  }

  private async prepareHttpRequest<T>(
    path: string,
    headers?: Record<string, string> | object,
    method?: RequestMethod,
    body?: Record<string, unknown> | object,
    formData?: boolean,
    timeout?: number,
    retry?: number,
    preventFastFlickering?: boolean,
    minimum?: number,
    isFileDownload?: boolean
  ): Promise<ApplicationHttpResponse<T>> {
    switch (method) {
      case API_REQUEST_METHODS.POST:
        return this.postData<T>(path, headers, body, formData, timeout, retry, preventFastFlickering, minimum);
      
      case API_REQUEST_METHODS.PUT:
        return this.putData<T>(path, headers, body, timeout, retry, preventFastFlickering, minimum);
      
      case API_REQUEST_METHODS.DELETE:
        return this.deleteData<T>(path, headers, body, formData, timeout, retry, preventFastFlickering, minimum);
      
      default:
        return this.getData<T>(path, headers, timeout, retry, preventFastFlickering, minimum, isFileDownload);
    }
  }

  /** GET */
  private async getData<T>(
    url: string,
    headers?: Record<string, string> | object,
    timeout?: number,
    retry?: number,
    preventFastFlickering?: boolean,
    minimum?: number,
    isFileDownload?: boolean
    ): Promise<ApplicationHttpResponse<T>> {
    const params: Record<string, unknown> = {};

    Object.assign(params, { method: API_REQUEST_METHODS.GET });
    Object.assign(params, { headers: headers });

    try {

      return await this.optimizedFetch<T>(url, params, timeout, retry, preventFastFlickering, minimum, isFileDownload);

    } catch (error: unknown) {

      throw new HTTPError((error as Error).message);

    }
  }

  /** POST */
  private async postData<T>(
    url: string,
    headers?: Record<string, string> | object,
    body?: Record<string, unknown> | object,
    formData?: boolean,
    timeout?: number,
    retry?: number,
    preventFastFlickering?: boolean,
    minimum?: number
    ): Promise<ApplicationHttpResponse<T>> {
    const params: Record<string, unknown> = {};

    if (this.isString(body) || formData) {
      Object.assign(params, { body: body });
    } else {
      Object.assign(params, { body: JSON.stringify(body) });
    }

    Object.assign(params, { method: API_REQUEST_METHODS.POST });
    Object.assign(params, { headers: headers });

    try {

      return await this.optimizedFetch<T>(url, params, timeout, retry, preventFastFlickering, minimum);

    } catch (error: unknown) {

      throw new HTTPError((error as Error).message);

    }
  }

  /** PUT */
  private async putData<T>(
    url: string,
    headers?: Record<string, string> | object,
    body?: Record<string, unknown> | object,
    timeout?: number,
    retry?: number,
    preventFastFlickering?: boolean,
    minimum?: number
    ): Promise<ApplicationHttpResponse<T>> {
    const params: Record<string, unknown> = {};

    if (this.isString(body)) {
      Object.assign(params, { body: body });
    } else {
      Object.assign(params, { body: JSON.stringify(body) });
    }

    Object.assign(params, { method: API_REQUEST_METHODS.PUT });
    Object.assign(params, { headers: headers });

    try {

      return await this.optimizedFetch<T>(url, params, timeout, retry, preventFastFlickering, minimum);

    } catch (error: unknown) {

      throw new HTTPError((error as Error).message);
    
    }
  }

  /** DELETE */
  private async deleteData<T>(
    url: string,
    headers?: Record<string, string> | object,
    body?: Record<string, string> | object,
    formData?: boolean,
    timeout?: number,
    retry?: number,
    preventFastFlickering?: boolean,
    minimum?: number
    ): Promise<ApplicationHttpResponse<T>> {
    const params: Record<string, unknown> = {};

    if (this.isString(body) || formData) {
      Object.assign(params, { body: body });
    } else {
      Object.assign(params, { body: JSON.stringify(body) });
    }

    Object.assign(params, { method: API_REQUEST_METHODS.DELETE });
    Object.assign(params, { headers: headers });

    try {

      return await this.optimizedFetch<T>(url, params, timeout, retry, preventFastFlickering, minimum);

    } catch (error: unknown) {

      throw new HTTPError((error as Error).message);
    
    }
  }

  /**
   * Effettua una richiesta HTTP ottimizzata con gestione di timeout e ri-tentativi.
   *
   * @param {string} url URL della risorsa da richiedere.
   * @param {Record<string, unknown>} params Parametri della richiesta.
   * @param {number} [timeout] Timeout della richiesta in millisecondi.
   * @param {number} [retry] Numero massimo di ritentativi in caso di errore.
   * @returns {Promise<ApplicationHttpResponse<T>>} Una Promise che restituisce la risposta della richiesta.
   */
  private async optimizedFetch<T>(
    url: string,
    params: Record<string, unknown>,
    timeout?: number,
    retry?: number,
    preventFastFlickering?: boolean,
    minimum?: number,
    isFileDownload?: boolean
  ): Promise<ApplicationHttpResponse<T>> {
    let response;
    
    try {
      if (this.flickeringTimeout) clearTimeout(this.flickeringTimeout);
      const start = window.performance.now();
      const controller = new AbortController();
      const abortFetch = timeout ? setTimeout(() => controller.abort(), timeout) : undefined;
  
      const init: Record<string, unknown> = { ...params, signal: controller.signal };
      if (this.env.storeAccessToken === "cookies") {
        init["credentials"] = "include";
      }

      response = await fetch(url, init);

      if (abortFetch) clearTimeout(abortFetch);

      if (isFileDownload && response.status === 200) {
        let filename: string | undefined = "";
        filename = response.headers.get("Content-Disposition")?.split(";")[1].split("filename")[1].split("=")[1].trim();
        const blob = await response.blob();
        const fileUrl = URL.createObjectURL(blob);

        return {
          error: "null",
          response: {
            fileName: filename,
            fileUrl: fileUrl
          }
        } as ApplicationHttpResponse<T>;
      }
  
      const jsonResponse = await response.json() as ApplicationHttpResponse<T>;
      
      const end = window.performance.now();

      // Questo controllo esiste per evitare un rapido "sfarfallio" del contenuto, nel caso in cui le dipendenze vengano recuperate troppo rapidamente.
      const DEFAULT_MIN_FLICKERING_TIME = 1000;
      if (preventFastFlickering && end - start < (minimum ?? DEFAULT_MIN_FLICKERING_TIME)) {

        const minCalcTime = (minimum ?? DEFAULT_MIN_FLICKERING_TIME) - (end - start);
        return await new Promise(res => 
          this.flickeringTimeout = setTimeout(res, minCalcTime, jsonResponse)
        );
      } else {
        return jsonResponse;
      }
    } catch (error: unknown) {
      if (retry && retry > 0) {
        return await this.optimizedFetch(url, params, timeout, retry - 1);
      }

      throw new Error(response?.status.toString());
    }
  }


  /**
   * 
   * Setta l'URL della chiamata in base ai parametri passati.
   * 
   * @param baseURL 
   * @param serviceName 
   * @param params 
   */
  protected createPath(baseURL: string, serviceName: string, queryParam?: Record<string, unknown> | object, pathParam?: string[]): string {
    /** Crea la prima parte del path, controllando se il baseURL ha già lo slash finale e in caso lo aggiunge */
    
    let path = baseURL + (baseURL.endsWith("/") ? "" : "/") + (serviceName.startsWith("/") ? serviceName.replace("/", "") : serviceName);

    /** Aggiunge al path eventuali urlParam, controllando se c'è già lo slash finale */
    if (pathParam) {
      path += (path.endsWith("/") ? "" : "/") + pathParam.join("/");
    }

    /** Aggiunge al path eventuali queryParam */
    if (!queryParam) {
      return path;
    }

    const QUERY_PARAMS = new URLSearchParams();
    /** Esegue il parse di ogni valore non string in tipo string */
    for (const [key, value] of Object.entries(queryParam)) { 

      /** Se undefine non viene aggiunto il parametro al path */
      if (value === undefined) {
        continue;
      }

      /** Se viene passato un array */
      if (Array.isArray(value)) {
        /** Elimina tutti i valori falsy */
        const arr = value.filter(Boolean);

        for (const field of arr) {

          if (this.isDate(field)) {
            /** Nel caso sia una data viene convertita in UTC */
            QUERY_PARAMS.append(key, field.toISOString());
          }

          if (this.isString(field)) {
            /** Nel caso sia una stringa aggiunge semplicemente il valore al path */
            QUERY_PARAMS.append(key, field);
          }

          if (!this.isDate(field) && !this.isString(field)) {
            /** In tutti gli altri casi converti in stringa il valore */
            QUERY_PARAMS.append(key, JSON.stringify(field));
          }
        }

        continue;
      }

      if (this.isDate(value)) {
        /** Nel caso sia una data viene convertita in UTC */
        QUERY_PARAMS.append(key, value.toISOString());
      }

      if (this.isString(value)) {
        /** Nel caso sia una stringa aggiunge semplicemente il valore al path */
        QUERY_PARAMS.append(key, value);
      }

      if (!this.isDate(value) && !this.isString(value)) {
        /** In tutti gli altri casi converti in stringa il valore */
        QUERY_PARAMS.append(key, JSON.stringify(value));
      }
    }

    return path += "?" + QUERY_PARAMS.toString();
  }

  /**
   * 
   * Setta l'header della chiamata.
   * 
   * Di default se presente viene anche il Bearer token se presente un token di autenticazione 
   * 
   * @param header 
   */
  private createHeaders(header?: Record<string, string> | object, formData?: boolean): Record<string, string> | undefined {
    const headers: Record<string, string> = { ...header };

    if (!formData) {
      headers["Content-Type"] = "application/json";
    }

    if (!this.token) {
      return JSON.stringify(headers) == "{}" ? undefined : headers;
    }
    
    const loginInfo: unknown = this.token;
    if (this.isLoginInfo(loginInfo)) {
      Object.assign(headers, { Authorization: `Bearer ${loginInfo.token}` });
    }
    
    return headers;
  }
  
  private get token(): BaseLoginInfo | undefined {
    let token: string | null | undefined;
    const { storeAccessToken } = this.env;
    
    switch (storeAccessToken) {
      case "sessionstorage": {
        const tokenKey: string | null = sessionStorage.getItem(this.env.loginType === "local" ? this.env.localTokenKey : this.env.ssoTokenKey);
        if (!tokenKey) throw new ClientError({ error: "AUTH_TOKEN_MISSING" });
        token = sessionStorage.getItem(tokenKey);
        
        break;
      }
    
      default: {
        const tokenKey: string | null = this.env.loginType === "local" ? this.env.localTokenKey : this.env.ssoTokenKey;
        if (!tokenKey) throw new ClientError({ error: "AUTH_TOKEN_MISSING" });
        token = document.cookie.split(";")
          ?.find((item) => item?.trim()?.startsWith(`${tokenKey}=`))
          ?.split(`${tokenKey}=`)[1];

        break;
      }
    }
    
    if (token) {
      return JSON.parse(token) as BaseLoginInfo;
    }

    return undefined;
  }

  /**
   * 
   * Controlla se il parametro è una data
   * 
   * @param date 
   */
  private isDate(date: unknown): date is Date {
    return toString.call(date) === "[object Date]";
  }

  /**
   * 
   * Controlla se il parametro è una string
   * 
   * @param value 
   */
  private isString(value: unknown): value is string {
    return typeof value === "string";
  }

  /**
   * 
   * Controlla se l'oggetto passato è del tipo LoginInfo
   * 
   * @param obj 
   * @returns {boolean}
   */
  private isLoginInfo(obj: unknown): obj is BaseLoginInfo {
    if (typeof obj === "object" && obj)
      return obj && typeof obj === "object" && "expireDate" in obj && "jwtPayload" in obj && "token" in obj;
    return false;
  }

  protected isError(obj: unknown): obj is ClientError {
    return Object.prototype.toString.call(obj) === "[object Error]";
  }
}
